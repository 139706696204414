.div-container-home-app {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.img-home-app {
  width: 20rem;
}

.bg-home-app {

  /* background-image: url("../assets/bgHomeApp.png"); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  /* background-color: #9ce8cb; */
  /* background: linear-gradient(140.63deg, #101f22 0%, #11765f 100%); */
  padding: 5rem 0rem;
}

.div-tittle-home {
  width: 40%;
}

.div-tittle-home h2 {
  text-align: center;
  padding: 2rem 0rem;
}
.div-tilt{
  background:none !important;
  background-image: URL('../assets/homeApp.webp') !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  height: 60vh;
}


@media (max-width:1440px) {
    
}
@media (max-width:1024px) {
    .div-tittle-home {
        width: 50%;
      }
    .div-tittle-home h2 {
        padding-bottom: 1rem;
      }
}
@media (max-width:768px) {
    .div-container-home-app {
flex-direction: column-reverse;
gap:2rem
      }
      .div-tittle-home {
        width: 90%;
      }
    
}
@media (max-width:425px) {
    
}