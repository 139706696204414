.div-footer {
    height: 100%;
    background: linear-gradient(140.63deg, #101f22 0%, #11765f 100%);
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    
}

.div-footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.div-container-copyright{
    color: antiquewhite;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}

.icon-social{
    width: 2rem;

}

.div-social-icons{
    display: flex;
    gap: 1rem;
}

.div-suscribe{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.div-form {
    color: aliceblue;
    height: 3rem;
    width: 20rem;
}

.div-form button {
border: none;
border-radius: 8px;
}
.div-logo-social .logo-vm {
    width: 10rem;
}

.enlarge-on-hover {
    color: antiquewhite;
    transition: transform 0.2s; /* Agrega una transición suave de 0.2 segundos */
  }
  
  .enlarge-on-hover:hover {
    transform: scale(1.05); /* Aumenta el tamaño en un 5% al pasar el cursor */
  }

  
  

@media (max-width:1440px) {}
@media (max-width:1024px) {
    .div-footer{
        height: 100%;
    }
}
@media (max-width:768px) {

    .div-footer-container{
        justify-content: space-around;
    }
    .div-container-copyright{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

}
@media (max-width:425px) {
    .div-footer-container{
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        text-align: center;
    }
    .div-footer {
        gap: 1rem;
    }
}