*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.div-banner{
    height: 60vh;
    width: 100%;
    display: flex;
}

.banner-01{
background-image: url('../assets/banner01img.webp');
width: 50%;
height: 100%;
background-position: center;
background-size: cover;
}
.banner-02{
    background-image: url('../assets/banner02img.webp');
    width: 50%;
    height: 100%;
    background-position: center;
background-size: cover;
}

.banner-01:hover, .banner-02:hover{
    opacity: 80%;
}

@media (max-width:1024px) {
    .div-banner{
        flex-wrap: wrap;
        height: 120vh;
    }
    .banner-01, .banner-02{
        width: 100%;
        height: 50%;

    }
}