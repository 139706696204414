.div-loading{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(180deg, #181c36 0%, #11765f 39%, #11765f 53.71621621621622%, #11765f 65%, #101f22 100%);
}

.loading-item{
    color: #101F22 !important;
}

.logo-vm-loading{
    width: 25%;
    
}