
.text-logo-vital-move{
    width: 15rem;
}

.div-img-vital-move-app{
    background-image: url('../assets/vitalMove001.webp');
    width: 50%;
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.h2-title-vital{
    display: flex;
    gap: 1rem;
    align-items: center;
}


.img-vital-move-app{
    width: 18rem;
}

/* .div-text-vital-move-app{

} */

.div-text-vital-move-app p {
    width: 100%;
}


@media (max-width:1440px) {
    .div-text-vital-move-app p {
        width: 95%;
    }
}
@media (max-width:1024px) {
    .div-img-vital-move-app{

        width: 100%;

    }
}
@media (max-width:768px) {}
@media (max-width:425px) {
    .h2-title-vital{
        gap: .5rem;
        justify-content: center;
    }

    .text-logo-vital-move{
        width: 11rem;
    }
}
@media (max-width:375px) {
    .div-total-move p {
        width: 100%;
    }
}