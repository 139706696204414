* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap');



.div-header{
    height: 100vh;
    /* background: linear-gradient(180deg, #181c36 0%, #11765f 39%, #11765f 53.71621621621622%, #11765f 65%, #101f22 100%); */
    background-image: url('../assets/67.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 150%;
    width: 100%;

}
.logo-vm {
    width: 6rem;
    transition:  ease-in .5s;
}
.logo-vm:hover{
    transform: scale(101%);
}


.div-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    flex-wrap: wrap;
}

.div-actions a {
    text-decoration: none;
    padding: 0rem .8rem;
}

.btn-login{
    text-decoration: none;
    color: aliceblue;
    background-color: #08c67d;
    padding: .4rem 3rem;
    border-radius: 8px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10pt;
}
.btn-login:hover{
    color: #101f22;
}

.div-hero-app{
    height: 80vh;
    display: flex;
    padding: 2rem 2rem;
    justify-content: space-around;
    align-items: center;
}
.div-hero-text{
    background-color: rgba(255, 255, 255, 30%);
    width: 450px;
    padding: 1rem 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo-black-vm{
    width: 250px;
    padding: 15px 0px;
}

.div-hero-text p {
    width: 90%;
    text-align: justify;
    font-size: 1em;
    font-family: 'Nunito Sans', sans-serif;
}
.div-logo-stores{
    display: flex;
     gap: 3rem;
     padding: 1rem 0rem;
     justify-content: center;
    
}

.logo-stores {
    width: 140px;
    cursor: pointer;
}

.logo-stores:hover {
    transform: translateY(3px);
}
.img-android-app{
width: 380px;

}
.logo-black{
    display: none;
}


.img-android-app-responsive{
    display: none;
}


/* Responsive */

@media (max-width:1440px) {
    .div-hero-app{
    padding: 5rem;    
    }
    .div-navbar{
        width: 100%;
    }
    
}
@media (max-width:1024px) {
.div-hero-text{
    width: 50%;
    padding: 1rem 1rem;
}
.logo-black-vm{
    width: 60%;
}
.div-hero-text p{
    width: 100%;
}

.img-android-app {
    width: 35%;
}
    
}
@media (max-width:768px) {
    .logo-light{
        display: none;
    }

    .logo-black{
        display: block;
        width: 9rem !important;
    }
    .img-android-app {
        display: none;
    }
    .img-android-app-responsive{
        display: block;
        width: 23rem;
    }




    .div-hero-text{
        width: 400px;
        padding: 1rem 1rem;
    }
    .div-header{
        width: 100%;
        /* background: linear-gradient(180deg, #181c36 0%, #11765f 39%, #11765f 53.71621621621622%, #11765f 65%, #101f22 100%); */
        background:#FAFAFA;
        height: 100%;
         }
    .div-navbar {
        width: 100%;
        justify-content: space-between;
    }
    .logo-vm{
        width: 5rem;
    }
    .div-hero-app{
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 2rem;
        height: 100%;
    }

}
@media (max-width:425px) {
    .div-hero-text {
        width: 23rem;
    }
    .div-logo-stores{
        gap: 1.5rem;
    }
    .div-navbar{
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width:375px) {
.div-navbar{
    flex-direction: column;
    gap: 2rem;
}
.div-header{
    width: 375px;
    height: 154vh;
}
.div-hero-text{
    width: 21rem;
}
.img-android-app{
    width: 20rem;
}
.logo-stores {
    width: 9rem;

}
}

@media (max-width:320px) {
    .div-navbar{
        background-color: #08c67d;
        display: flex;
        justify-content: center;
        width: 320px;

    }
    .div-hero-app{
        width: 320px;
    }
    .div-hero-text{
        width:18rem;
    }
}