.android-app-vm{
    width: 40rem;
}
.div-container-vm{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    
}

.div-vm-app{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75%;
}
.div-card-vm{
    display: flex;
    align-items: center;
    width: 22rem;
    height: 10rem;
    gap: 2rem;
}
.img-vm-app{
    width: 3rem;
    height: 3rem;
}

.div-text-vm-app{
    align-self: baseline;
}
.div-container-vm-app{
    width: 50%;
}
.div-tittle-vm-app{
display: flex;
flex-direction: column;
align-items: center;
    text-align: center;
    padding: 4rem 5rem;
}
.text-tittle-vm-app{
    width: 30rem;
    text-align: justify;
}
.tittle-vm-app{
    font-weight: bold;
}
.div-text-vm-app p{
    text-align: justify;
}
.app-responsive{
    display: none;
}


@media (max-width:1440px) {
 .div-container-vm-app{
    padding: 0;
 }   
 .div-tittle-vm-app{
    padding: 1rem;
 }
 .android-app-vm{
    align-self: center;
 }
 .div-card-vm{
    width: 80%;
 }
}

@media (max-width:1024px) {
    .android-app-vm{
        display: none;
     }
     .app-responsive{
        display: block;
     }
}

@media (max-width:768px) {
    .div-container-vm{
        flex-direction: column-reverse;
        align-items: center;
        gap: 2rem;
    }
    .app-responsive{
        width: 18rem;
    }
    .div-card-vm{
        width: 100%;
     }
     .div-container-vm-app{
        width: 80%;
     }
}

@media (max-width:425px) {

    .text-tittle-vm-app{
        width: 100%;
    }
    .div-container-vm-app{
        width: 90%;
     }

}

@media (max-width:375px) {

}