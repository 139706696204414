.div-bg-carousel{
    height: 80vh;
}

.div-carousel{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.div-carousel-img{
    display: flex;
    justify-content: center;
width: 100%;
}

.swiper {
    width: 80rem;
    height: 39rem;
    /* position: absolute; */
    /* left: 50%; */
    /* top: 50%; */

  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .div-icon-carousel{
    display: flex;
    justify-content: space-around;
    align-items: center;

  }

  
.img-carousel{
    width: 30rem !important;
}

.div-info{
    display: flex;
    flex-direction: column;
    height: 15rem;
    width: 25rem;
    gap: 1.5rem;
}

.img-logo-app{
    width: 3rem !important;
}



@media (max-width:1024px) {
    .swiper{
        width: 100%;
    }

}
@media (max-width:768px) {

    .div-carousel{
        height: 100%;
    }
    
    .div-icon-carousel{
        flex-direction: column;
        gap: 2rem;
        height: 100%;
        justify-content: start;
    }

    .img-carousel{
        width: 40% !important;
    }
}
@media (max-width:425px) {

    .div-carousel {
        /* display: none; */
        width: 90%;
height: 100%;
    }
    .div-info{
        width: 20rem;
        box-shadow: none !important;
    }
    .img-carousel{
        width: 80% !important;
    }
    .logo-stores {
        width: 7rem !important;
    }

}