.div-store{
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 5rem 1rem;
    align-items: center;
}

.img-store-phone{
    width: 400px;
}

.img-store-phone:hover{
    transform: scale(101%);
}

.div-download-app{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-download-app:hover{
    transform: scale(101%);
}

@media (max-width:1440px) {
    .div-store{
        height: 100%;
    }
}
@media (max-width:1024px) {
    .div-store{
        justify-content: space-between;
    }
}
@media (max-width:768px) {
    .div-store{
        flex-wrap: wrap;
        justify-content: center;
        gap: 5rem;
        align-items: center;

    }
    .img-store-phone{
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
@media (max-width:425px) {
    .img-store-phone{
        width: 100%;
    }
    .div-download-app{
        width: 100%;
    }
}