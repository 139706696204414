* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.div-vital-move-app {
  display: flex;
}
.div-img-vital-move {
  width: 50%;
  background-image: url("../assets/iphoneAppLogin.webp");
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-vital-move {
  display: flex;
}
.hand-phone {
  width: 4rem;
  margin-bottom: 2rem;
}

.div-text-vital-move {
  width: 50%;
  display: flex;
  align-items: center;
}

.div-text-vital-move p {
  text-align: justify;
  width: 75%;
}
.div-text-vital-move h2 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.div-total-move {
  margin-left: 20%;
  width: 38rem;
}

@media (max-width:1440px) {
    .div-vital-move-app{
        width: 100%;
    }
    
.div-total-move {
    margin-left: 10%;
    width: 35rem;    
  }
  .div-text-vital-move p {
    width: 95%;
  }
}

@media (max-width:1024px) {
  .div-vm-second-info{
    display: flex !important;
    flex-direction: column-reverse !important;
  }
    .div-vital-move-app{
       flex-direction: column;
    }
.div-img-vital-move{
    width: 100%;
}
.div-text-vital-move{
    justify-content: center;
}
.div-text-vital-move {
    width: 100%;
    padding: 2rem 10rem;
  }
  .div-total-move {
   align-self: center;
   justify-self: center;
   margin: 0;
   width: 100%;
  }
  .div-text-vital-move p {
    width: 100%;
  }
  
}

@media (max-width:768px) {

.div-text-vital-move{

    padding: 5rem;
}

  
}

@media (max-width:425px) {

    .div-text-vital-move{
    
        padding: 1rem;
    }
    
      
    }